import BlogList from "../components/Blog";

const Blog = () => {
  return (
    <div>
      <BlogList />
    </div>
  );
};
export default Blog;
